import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/utils/misc.ts';

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement>,
		VariantProps<typeof inputVariants> {}

export const inputVariants = cva(
	'flex h-10 w-full px-3 py-2 text-sm file:border-0 file:bg-transparent aria-[invalid]:border-input-invalid file:text-sm disabled:opacity-50 file:font-medium disabled:cursor-not-allowed placeholder:italic  placeholder:text-muted-foreground',
	{
		variants: {
			variant: {
				default:
					'rounded-md border border-input bg-background ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
				underline:
					'border-b focus-visible:bg-muted rounded-t-md border-input bg-transparent ring-offset-background focus-visible:outline-none focus-visible:border-primary transition-colors',
				ghost:
					'border-0 focus-visible:bg-muted rounded-md bg-transparent ring-offset-background focus-visible:outline-none transition-colors',
			},
		},
	},
);

export const inputClassName = inputVariants({ variant: 'default' });

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, variant, ...props }, ref) => {
		return (
			<input
				type={type}
				className={cn(
					inputVariants({ variant: variant || 'default', className }),
				)}
				ref={ref}
				{...props}
			/>
		);
	},
);
Input.displayName = 'Input';

export { Input };
